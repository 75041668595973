import React from "react";
import { Button } from "antd";
import BDImage from "../../assets/BD.png";
import BDBImage from "../../assets/BD-B.png";

// First row labels
const buttonLabels = ["Giới thiệu", "Dự án", "BDA Studio"];
// Second row labels
const secondRowLabels = ["Về BDA", "Kiến trúc độc bản", "Tuyển dụng"];

const Header = ({ onPress, selectedIndex }) => {
  const backgroundColors = ["transparent", "transparent", "#e0e0e0", "#fff"]; // Background colors for each section

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: backgroundColors[selectedIndex] || "#fff",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        transition: "background-color 0.5s ease", // Smooth background color transition
      }}
    >
      {/* Left section: Logo */}
      <img
        src={selectedIndex < 2 ? BDImage : BDBImage}
        alt="logo"
        style={{ height: "50px" }}
        onClick={() => onPress(0)}
      />

      {/* Middle section: Wrapping both rows inside the same div */}
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Stack rows vertically
          alignItems: "center", // Center the rows horizontally
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          {buttonLabels.map((label, index) => (
            <Button
              key={index}
              type="link"
              style={{
                color: selectedIndex === index + 1 ? "black" : "#C5C5C5", // Active/Inactive color
              }}
              onClick={() => onPress(index + 1)} // Pass correct index to parent
            >
              {label}
            </Button>
          ))}
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          {secondRowLabels.map((label, index) => (
            <Button
              key={index}
              type="link"
              style={{
                color: selectedIndex === 4 && index === 2 ? "black" : "#C5C5C5", // Active/Inactive color
              }}
              onClick={() => onPress(4)} // Pass correct index for second row
            >
              {label}
            </Button>
          ))}
        </div>
      </div>

      {/* Right section: Empty space (adjust as needed) */}
      <div style={{ width: "50px" }}></div>
    </div>
  );
};

export default Header;
