import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { motion } from "framer-motion";

// Importing images
import vector5 from "../../assets/vector-5.svg";
import ru1 from "../../assets/ru-1.svg";
import vector4 from "../../assets/vector-4.svg";
import ru02 from "../../assets/ru-02.svg";
import officialLogo from "../../assets/official.svg";

const Intro = () => {
  const navigate = useNavigate();

  // Handle manual click to navigate
  const onContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  // Automatically navigate to '/' after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 6000); // 2 seconds

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="div" onClick={onContainerClick}>
      <div className="child">
        <div className="logo">
          <motion.img
            className="item"
            alt=""
            src={vector5}
            initial={{ scale: 1, rotate: 0, borderRadius: "0%", opacity: 1 }}
            animate={{
              scale: [0.1, 1.2, 1.2, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
            }}
          />
          <motion.img
            className="ru-1-icon"
            alt=""
            src={ru1}
            initial={{ opacity: 0, y: -200 }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 2,
              ease: "easeInOut",
            }}
          />
          <motion.img
            className="inner"
            alt=""
            src={vector4}
            animate={{
              opacity: [0, 1],
            }}
            transition={{
              duration: 0.5,
              delay: 2.5,
              ease: "easeInOut",
            }}
          />
          <motion.img
            className="ru-02-icon"
            alt=""
            src={ru02}
            animate={{
              opacity: [0, 1],
            }}
            transition={{
              duration: 0.5,
              delay: 3,
              ease: "easeInOut",
            }}
          />
        </div>
        <motion.img
          className="image-logo"
          alt=""
          src={officialLogo}
          animate={{
            opacity: [0, 1],
          }}
          whileTap={{ scale: 1.1 }}
          transition={{
            duration: 0.5,
            delay: 3,
            ease: "easeInOut",
          }}
        />
      </div>
    </div>
  );
};

export default Intro;
