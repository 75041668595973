import React from "react";
import JobImage from "../../assets/section4.png"; // Replace with the actual image

const SectionFour = () => {
  // Table data
  const jobData = [
    {
      stt: "01",
      jobTitle: "Thực tập sinh kiến trúc",
      quantity: "02",
      location: "TP. Hồ Chí Minh",
      deadline: "8/8/2024",
    },
    {
      stt: "02",
      jobTitle: "Kiến trúc sư",
      quantity: "01",
      location: "TP. Hồ Chí Minh",
      deadline: "8/9/2024",
    },
    {
      stt: "03",
      jobTitle: "Nhân viên Marketing",
      quantity: "01",
      location: "TP. Hồ Chí Minh",
      deadline: "8/9/2024",
    },
  ];

  return (
    <div
      style={{
        padding: "50px 20px",
        color: "white",
      }}
    >
      {/* Top Section: Title, Description, and Image (side by side) */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexWrap: "wrap", // Allow wrapping on smaller screens
          marginBottom: "30px",
          textAlign: "left",
        }}
      >
        {/* Left Section: Title and Description */}
        <div
          style={{
            flex: "1",
            paddingRight: "20px",
            maxWidth: "50%",
            textAlign: "right",
          }}
        >
          <h2
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            NGÔI NHÀ CHUNG BDA
          </h2>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "1.6",
              textAlign: "left",
              color: "white",
            }}
          >
            Đối với chúng tôi, mỗi "chiếc hộp" đều là độc bản, chưa từng được
            dựng bên trong người hoạ sĩ tài ba mà còn đa không gian pha hợp để
            bảo vệ đặc biệt, cở mở tư tin nhưng nét vẫn từ tính của mình. Với
            tinh thần "sáng tạo không biên giới", chiếc hộp BDA Architects luôn
            hướng đến mục tiêu kiên tạo ra VŨ TRỤ cho những chỉnh thể, nơi mà họ
            được tư do tuỳ biến, nhỏ nhắn, sáng tạo cái đẹp sự bền vững với bản
            sắc nằm sâu bên trong tâm hồn.
          </p>
        </div>

        {/* Right Section: Image */}
        <div style={{ flex: "1", maxWidth: "50%" }}>
          <img
            src={JobImage}
            alt="Job"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </div>
      </div>

      {/* Bottom Section: Table-like Structure */}
      <div style={{ maxWidth: "1000px", margin: "0 auto", textAlign: "left" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "30px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  borderRight: "1px solid #ccc",
                }}
              >
                STT
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  borderRight: "1px solid #ccc",
                }}
              >
                Vị trí tuyển dụng
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  borderRight: "1px solid #ccc",
                }}
              >
                Số lượng
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  borderRight: "1px solid #ccc",
                }}
              >
                Khu vực
              </th>
              <th style={{ padding: "10px", textAlign: "left" }}>
                Ngày hết hạn
              </th>
            </tr>
          </thead>
          <tbody>
            {jobData.map((job, index) => (
              <tr key={index}>
                <td style={{ padding: "10px", borderRight: "1px solid #ccc" }}>
                  {job.stt}
                </td>
                <td style={{ padding: "10px", borderRight: "1px solid #ccc" }}>
                  {job.jobTitle}
                </td>
                <td style={{ padding: "10px", borderRight: "1px solid #ccc" }}>
                  {job.quantity}
                </td>
                <td style={{ padding: "10px", borderRight: "1px solid #ccc" }}>
                  {job.location}
                </td>
                <td style={{ padding: "10px" }}>{job.deadline}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "50px 20px",
          alignItems: "flex-start",
        }}
      >
        {/* Left section with large "+2" */}
        <div
          style={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "100px",
            fontWeight: "300",
            color: "#e0e0e0",
          }}
        >
          +2
        </div>

        {/* Middle section with title and details */}
        <div style={{ flex: "2", paddingRight: "20px" }}>
          {/* Main Title */}
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            THỰC TẬP SINH KIẾN TRÚC
          </h2>

          {/* Sections with bullet points */}
          <div style={{ marginBottom: "20px" }}>
            <h4 style={{ marginBottom: "10px" }}>• Mô tả công việc</h4>
            <ul style={{ marginLeft: "20px", lineHeight: "1.8" }}>
              <li>Mô tả triển khai hoàn chỉnh hồ sơ thiết kế.</li>
              <li>Hỗ trợ các bộ phận liên quan.</li>
              <li>Hỗ trợ hoàn chỉnh hồ sơ thầu và thiết kế.</li>
            </ul>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <h4 style={{ marginBottom: "10px" }}>• Yêu cầu</h4>
            <ul style={{ marginLeft: "20px", lineHeight: "1.8" }}>
              <li>Sinh viên học chuyên ngành kiến trúc năm 3 hoặc năm 4.</li>
              <li>Sử dụng thành thạo các phần mềm AutoCAD, Sketchup, Revit.</li>
              <li>Có đam mê và tinh thần học hỏi.</li>
            </ul>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <h4 style={{ marginBottom: "10px" }}>• Điều kiện làm việc</h4>
            <ul style={{ marginLeft: "20px", lineHeight: "1.8" }}>
              <li>
                Thời gian làm việc: 8h00 - 12h00, 13h30 - 17h30, từ thứ 2 đến
                thứ 6.
              </li>
              <li>
                Địa điểm: 18-10 KDC và công viên Phước Thiện, Số 88 Phước Thiện,
                KP 27 P Long Bình TP Thủ Đức, TP. HCM.
              </li>
            </ul>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <h4 style={{ marginBottom: "10px" }}>• Hồ sơ ứng tuyển</h4>
            <ul style={{ marginLeft: "20px", lineHeight: "1.8" }}>
              <li>Email: bda@bdagroup.vn</li>
              <li>Zalo: (+84) 12345678</li>
            </ul>
          </div>
        </div>

        {/* Right section with date and year */}
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
          }}
        >
          {/* Date */}
          <div
            style={{
              fontSize: "18px",
              color: "#ccc",
              fontWeight: "bold",
              marginBottom: "50px",
            }}
          >
            SEPTEMBER
          </div>

          {/* Year */}
          <div style={{ fontSize: "32px", fontWeight: "bold" }}>2024</div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
