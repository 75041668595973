import React from "react";
import { Image, Col, Row } from "antd";
import projectImage1 from "../../assets/project1.png"; // Adjust paths as needed
import projectImage2 from "../../assets/project2.png";
import projectImage3 from "../../assets/project3.png";
import mostViewedImage1 from "../../assets/mostViewed1.png";
import mostViewedImage2 from "../../assets/mostViewed2.png";

const SectionTwo = () => {
  // Sample data for projects
  const projects = [
    {
      imgSrc: projectImage1,
      title: "The Simplify 2024 (Căn hộ Vinhomes 01)",
      client: "Lê Chí Phúc",
      details: "DSFSFC DFDV",
    },
    {
      imgSrc: projectImage2,
      title: "The Simplify 2024 (Căn hộ Vinhomes 01)",
      client: "Lê Chí Phúc",
      details: "DSFSFC DFDV",
    },
    {
      imgSrc: projectImage3,
      title: "The Simplify 2024 (Căn hộ Vinhomes 01)",
      client: "Lê Chí Phúc",
      details: "DSFSFC DFDV",
    },
  ];

  // Sample data for most viewed images
  const mostViewed = [
    { imgSrc: mostViewedImage1 },
    { imgSrc: mostViewedImage2 },
    { imgSrc: projectImage1 },
    { imgSrc: projectImage2 },
    { imgSrc: projectImage3 },
  ];

  return (
    <div
      style={{ padding: "40px 20px", fontFamily: "'Montserrat', sans-serif" }}
    >
      <Row gutter={[32, 32]}>
        {/* Left Column - Projects List */}
        <Col span={16}>
          {projects.map((project, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                marginBottom: "20px",
                borderBottom: "1px solid #e0e0e0",
                paddingBottom: "20px",
              }}
            >
              <Image
                src={project.imgSrc}
                alt={project.title}
                style={{ width: "300px", marginRight: "20px" }}
              />
              <div>
                <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {project.title}
                </h3>
                <p
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                >
                  Client: {project.client}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    color: "#666",
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                >
                  {project.details}
                </p>
              </div>
            </div>
          ))}
        </Col>

        {/* Right Column - Most Viewed Section */}
        <Col span={8}>
          <h2
            style={{
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "24px",
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            XEM NHIỀU NHẤT
          </h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
            }}
          >
            {mostViewed.slice(0, 5).map((item, index) => (
              <Image
                key={index}
                src={item.imgSrc}
                alt={`Most viewed ${index + 1}`}
                style={{ width: "100%", height: "auto" }}
              />
            ))}
            {/* Placeholder for "+5" overlay */}
            <div
              style={{
                position: "relative",
                backgroundColor: "#f0f0f0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "24px",
                color: "white",
                fontWeight: "bold",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              +5
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SectionTwo;
