import React from "react";
import { Image } from "antd";
import SectionImage from "../../assets/section3.png"; // Adjust path as needed

const SectionThree = () => {
  const articles = [
    "Chicland Hotel Longlisted for 2020 Dezeen Award",
    "Thang House Longlisted for 2020 Dezeen Award",
    "Ha Long Villa Longlisted for 2020 Dezeen Award",
    "Open Building Event: Club House",
    "VTN Architects Win Award for Ha House",
    "Chinese Art Publisher Features VTN Architects in New Book",
    "Works 'Castaway Island Resort' has been won the Gold metal of ARCASIA Award 2019",
    "Farming Kindergarten on VnExpress",
    // Add more articles as needed
  ];

  return (
    <div style={{ display: "flex", flexDirection: "row", padding: "20px" }}>
      {/* Left column: Articles list */}
      <div style={{ flex: 1, paddingRight: "20px" }}>
        <h3 style={{ textAlign: "right" }}>BÀI VIẾT</h3>
        <ul style={{ textAlign: "right", listStyleType: "none", padding: 0 }}>
          {articles.map((article, index) => (
            <li key={index} style={{ paddingBottom: "10px" }}>
              {article} <span style={{ fontWeight: "bold" }}>•</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Right column: Content */}
      <div style={{ flex: 2 }}>
        <h1 style={{ textAlign: "center" }}>TITLE</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis.
        </p>

        {/* Image and caption */}
        <Image
          src={SectionImage}
          alt="Section Image"
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <p style={{ textAlign: "left", fontStyle: "italic" }}>
          Nguồn: BDA Architects
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor.
        </p>
      </div>
    </div>
  );
};

export default SectionThree;
