import React, { useRef, useState, useEffect } from "react";
import Bg1Image from "../../assets/bg1.png";
import Bg3Image from "../../assets/bg3.png";
import Header from "./Header";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";

const Home = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]; // Refs for each section
  const [activeSection, setActiveSection] = useState(0); // Track active section index
  console.log("activeSection", activeSection);
  const handlePress = (index) => {
    sectionRefs[index].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setActiveSection(index); // Set active section manually when clicking
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const index = sectionRefs.findIndex(
          (ref) => ref.current === entry.target
        );

        // Set a timeout for updating the active section
        setTimeout(() => {
          setActiveSection(index); // Delay setting the active section
        }, 300); // Delay of 300ms (adjust as needed)
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.3, // Lower threshold to trigger earlier
    });

    sectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Cleanup the observer when component unmounts
    return () => {
      sectionRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs, handleIntersect]);

  const pageStyle = {
    backgroundImage: `url(${Bg1Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Full viewport height for the background section
    width: "100%",
  };

  return (
    <>
      <div ref={sectionRefs[0]} style={pageStyle}>
        <Header onPress={handlePress} selectedIndex={activeSection} />
      </div>

      <div
        ref={sectionRefs[1]}
        style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
      >
        <SectionOne />
      </div>

      <div
        style={{
          height: "100px",
          background: "linear-gradient(#f5f5f5, #e0e0e0)",
        }}
      />

      <div
        ref={sectionRefs[2]}
        style={{ height: "100vh", backgroundColor: "#e0e0e0" }}
      >
        <SectionTwo />
      </div>

      <div
        style={{
          height: "100px",
          background: "linear-gradient( #e0e0e0, #fff)",
        }}
      />
      <div ref={sectionRefs[3]} style={{ height: "100vh" }}>
        <SectionThree />
      </div>

      <div
        style={{
          height: "300px",
          background: "linear-gradient(  #fff,#545454)",
        }}
      />
      <div ref={sectionRefs[4]} style={{ backgroundColor: "#545454" }}>
        <SectionFour />
      </div>
    </>
  );
};

export default Home;
