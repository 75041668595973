import React from "react";
import { Image } from "antd";
import bannerImage from "../../assets/section1.png"; // Adjust path as necessary

const SectionOne = () => {
  return (
    <div style={{ padding: "0", margin: "0" }}>
      {/* Banner image section */}
      <div>
        <Image
          src={bannerImage}
          alt="banner"
          style={{ width: "100%", height: "auto" }}
        />
      </div>

      {/* Main content section */}
      <div style={{ textAlign: "center", padding: "40px" }}>
        <h1 style={{ fontSize: "30px", fontWeight: "bold" }}>BDA ARCHITECTS</h1>
        <p style={{ maxWidth: "800px", margin: "0 auto", lineHeight: "1.8" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis.
        </p>
        <p style={{ maxWidth: "800px", margin: "0 auto", lineHeight: "1.8" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis.
        </p>
      </div>

      {/* Footer section */}
      <footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px",
          backgroundColor: "#f5f5f5",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <span>EMAIL +</span>
        <span>CONTACT +</span>
        <span>SOCIAL +</span>
        <span>OFFICES +</span>
      </footer>

      {/* Copyright */}
      <div style={{ textAlign: "center", padding: "20px", fontSize: "12px" }}>
        Copyright © 2024 by BDA Group.vn
      </div>
    </div>
  );
};

export default SectionOne;
